/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'adminitrative',
        title: 'Gestion administrative',
        type: 'collapsable',
        icon: 'dashboard',
        children: [
            {
                id: 'customers',
                title: 'Gestion clients',
                type: 'basic',
                link: '/customers',
                icon: 'groups',
            },
            {
                id: 'folders',
                title: 'Dossiers et contrats',
                type: 'basic',
                link: '/folders',
                icon: 'folder_open',
            },
            {
                id: 'buildings-apartments',
                title: 'Immeubles & Apparts',
                type: 'basic',
                link: '/buildings',
                icon: 'apartment',
            },
            {
                id: 'houses',
                title: 'Villas',
                type: 'basic',
                link: '/houses',
                icon: 'corporate_fare',
            }
        ]
    },
    {
        id: 'exchange',
        title: 'Échanges Clients',
        type: 'collapsable',
        icon: 'switch_account',
        children: [
            {
                id: 'email',
                title: 'Messagerie',
                type: 'basic',
                link: '/mailbox',
                icon: 'mark_email_unread',
            },
            // {
            //     id: 'actuality',
            //     title: 'Actualités',
            //     type: 'basic',
            //     link: '/home',
            //     icon: 'feed',
            // },
            {
                id: 'promos',
                title: 'Biens En Promotions',
                type: 'basic',
                link: '/promos',
                icon: 'star',
            },
        ]
    },
    {
        id: 'gesture',
        title: 'Gestion Droits D\'accès',
        type: 'collapsable',
        icon: 'admin_panel_settings',
        children: [
            {
                id: 'backoffice',
                title: 'Comptes Back-Office',
                type: 'basic',
                link: '/users',
                icon: 'engineering',
            },
            {
                id: 'clients',
                title: 'Comptes Clients',
                type: 'basic',
                link: '/manage-customers',
                icon: 'folder_shared',
            }
        ]
    },
];
