import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';

import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutComponent } from './layout/layouts/empty/empty.component';


// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'home'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'sign-in'},

    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
        ]
    },
    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/admin/dashboard/dashboard.routes')},
        ]
    },
    // Admin routes
    {
        path: '',
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            {path: 'buildings', loadChildren: () => import('app/modules/admin/buildings/buildings.routes')},
            {path: 'houses', loadChildren: () => import('app/modules/admin/houses/houses.routes')},
            {path: 'customers', loadChildren: () => import('app/modules/admin/customers/customers.routes')},
            {path: 'folders', loadChildren: () => import('app/modules/admin/folders/folders.routes')},
            {path: 'mailbox', loadChildren: () => import('app/modules/mailbox/mailbox.routes')},
            {path: 'users', loadChildren: () => import('app/modules/users/users.route')},
            {path: 'manage-customers', loadChildren: () => import('app/modules/manage-customer/manage-customer.route')},
            {path: 'promos', loadChildren: () => import('app/modules/admin/promoted-items/promoted-items.routes')},

        ]
    },

    //external routes
    {
        path: 'external',
        component: EmptyLayoutComponent,
        children: [
            {path: '',loadChildren: ()=> import('app/modules/external/external.routes')}
        ]
    },
    { path: '**', pathMatch: 'full', redirectTo: '/home' }
];
