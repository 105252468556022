// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

    firebase: {
        apiKey: "AIzaSyAOsElYxlc6Hu7B_sROIsETywUMpd2vMcY",
        authDomain: "dgc-production.firebaseapp.com",
        projectId: "dgc-production",
        storageBucket: "dgc-production.appspot.com",
        messagingSenderId: "637279842748",
        appId: "1:637279842748:web:17ecdd5cde5ec4e549bbb9",
        measurementId: "G-83CP4ZB28H"
    },
    production: false,
    backOfficeEmails: ['m.hessas@hexaee.com','chettoutabdeslam@gmail.com']
};
