import { Injectable } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from '../user/user.types';

import { Auth, authState, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, UserCredential, confirmPasswordReset, verifyPasswordResetCode } from '@angular/fire/auth';
import { Firestore } from '@angular/fire/firestore';


@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;
    readonly DEFAULT_USER_AVATAR_MALE = '/assets/images/default/men.jpeg';
    readonly DEFAULT_USER_AVATAR_FEMALE = '/assets/images/default/women.jpeg';
    currentUser: BehaviorSubject<User> = new BehaviorSubject(null);
    constructor(
        private _userService: UserService,
        private auth: Auth,
        private firestore: Firestore,
    ) {
    }

    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    forgotPassword(email: string) {
        return sendPasswordResetEmail(this.auth, email);
    }
    resetPassword(oobCode: string, password: string) {
        return confirmPasswordReset(this.auth, oobCode, password);
    }

    verifyOobCode(code) {
        return verifyPasswordResetCode(this.auth, code.toString());
    }




    signIn(credentials: { email: string; password: string }): Promise<UserCredential> {
        return signInWithEmailAndPassword(this.auth, credentials.email, credentials.password);
    }
    signOut(): Promise<void> {
        return signOut(this.auth);
    }

    setToken(accessToken: string, user: User) {
        this.accessToken = accessToken;
        this._authenticated = true;
    }

    logout(): Promise<void> {
        localStorage.removeItem('accessToken');
        this._authenticated = false;
        return signOut(this.auth);
    }

    getCurrentUser(): Promise<User> {

        return new Promise(async (resolve) => {
            authState(this.auth).subscribe(async (user) => {
                let claims = await (await user.getIdTokenResult()).claims;
                if (!user) {
                    resolve(null);
                    this.currentUser.next(null);
                } else {
                    const u: User = {
                        avatar: this.DEFAULT_USER_AVATAR_MALE,
                        name: user.displayName,
                        email: user.email,
                        id: user.uid,
                        claims: claims
                    };
                    resolve(u);
                    this.currentUser.next(u);
                }
            });

        });
    }

    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return of(1);
    }

}
