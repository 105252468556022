import { Component, isDevMode, LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterOutlet } from '@angular/router';



@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [
        RouterOutlet,
    ],
    providers:[
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        {provide: LOCALE_ID, useValue: 'fr' }
    ],
})
export class AppComponent
{
    /**
     * Constructor
     */
    constructor()
    {
    }
}
