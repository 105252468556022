import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { defaultNavigation } from 'assets/data/pages';
import { cloneDeep } from 'lodash';
import { Observable, of, ReplaySubject, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        return of({ default: cloneDeep(defaultNavigation) } as Navigation)
            .pipe(
                tap((navigation) => {
                    //this._navigation.next({default: []} as Navigation);
                }),
            )
    }

    async loadNavigationMenu(role: string) {
        if (role == 'commercial-assistant' && defaultNavigation.length == 3) {
            this._navigation.next({ default: [defaultNavigation[0]] } as Navigation);
        } else this._navigation.next({ default: defaultNavigation } as Navigation);
    }
}
