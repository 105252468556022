import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable, ReplaySubject, of } from 'rxjs';
import { User } from './user.types';

@Injectable({ providedIn: 'root' })
export class UserService {

    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(private angularFireFunctions: AngularFireFunctions) { }

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User>
    {
        return this._user.asObservable();
    }
    
    updateUserStatus(user: any) {
        const updateUserStatusFn = this.angularFireFunctions.httpsCallable('updateUserStatus');
        return updateUserStatusFn({ ...user });
    }

    signUp(user: { name: string; email: string; password: string; company: string; agreements:string }): Observable<any> {
        return of(1);
    }
}
