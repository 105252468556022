import { inject } from '@angular/core';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { forkJoin } from 'rxjs';
import { AuthService } from './core/auth/auth.service';

export const initialDataResolver = () => {

    return new Promise<void>(async (resolve) => {
        const navigationService = inject(NavigationService);
        const authService = inject(AuthService);
        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin([
            navigationService.get(),
            authService.getCurrentUser(),
        ]).subscribe((res) => {
            navigationService.loadNavigationMenu(res[1].claims.role).then(() => {
                console.log('### app resolver resolve() here');
                resolve();
            });
        })
    });
};
